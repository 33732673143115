<template>
    <div>
        <CSBreadcrumb />
        <div class="filter-panel">
            <CSSelect>
                <el-date-picker
                    type="datetime"
                    v-model="queryParams.startDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择开始时间"
                    :editable="false"
                />
            </CSSelect>
            <span style="margin-right: 10px; margin-left: -10px;">-</span>
            <CSSelect>
                <el-date-picker
                    type="datetime"
                    v-model="queryParams.endDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择开始时间"
                    :editable="false"
                />
            </CSSelect>
            <input type="text" class="cs-input" placeholder="搜索姓名、手机号" v-model="queryParams.search">
            <CSSelect>
                <select v-model="queryParams.state">
                    <option value="">停车券使用状态不限</option>
                    <option v-for="(name, state) in couponUseState" :key="state" :value="state">{{ name }}</option>
                </select>
            </CSSelect>
            <button class="btn btn-primary" @click="getReceiveCouponLog()">查询</button>
        </div>

        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel">
                        <span>已领人次总计：{{ receiveLog.receivePeople }}</span>
                        <span>已领数量总计：{{ receiveLog.receiveNum }}</span>
                        <span>已使用数量总计：{{ receiveLog.useNum }}</span>
                        <span>已过期数量总计：{{ receiveLog.overdueNum }}</span>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>领取时间</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>所属企业</th>
                        <th>停车券使用状态</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="receiveLog.length > 0">
                        <tr v-for="log in receiveLog" :key="log.id">
                            <td>{{ log.createTime }}</td>
                            <td>{{ log.name }}</td>
                            <td>{{ log.phone }}</td>
                            <td>{{ log.companyName }}</td>
                            <td>{{ couponUseState[log.state] || '-' }}</td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination />
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import {receiveCounponLogUrl} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTable from "@/components/common/CSTable";

export default {
    name: "ParkCouponsLog",
    props: {
        id: [Number, String]
    },
    components: {
        CSTable,
        CSBreadcrumb,
        Pagination,
        CSSelect,
    },
    data() {
        return {
            filterHeight: 0,
            queryParams: {
                startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00'),//查询开始时间
                endDate: dayjs().format('YYYY-MM-DD 23:59'),//查询结束时间
                search: '',
                state: '',//1.未使用2.已使用3.已过期
                couponId: this.id,//停车券id 必填
            },
            couponUseState: {
                1: '未使用',
                2: '已使用',
                3: '已过期'
            },
            receiveLog: [],
        }
    },
    created() {
        this.getReceiveCouponLog();
        document.body.addEventListener('keydown', e => {
            if (e.key === 'Enter') {
                this.getReceiveCouponLog();
            }
        })
        this.$vc.on(this.$route.path, 'pagination_page', 'event', this.getReceiveCouponLog);
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    updated() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    },
    methods: {
        /**
         * 获取停车券领取记录
         * @param {Number} pageNo 页码
         * @param {Number} pageSize 查询数量
         * */
        getReceiveCouponLog(pageNo = 1, pageSize = 10) {
            this.$fly.post(receiveCounponLogUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                pageNo,
                pageSize,
                ...this.queryParams
            })
            .then(res => {
                if (res.code === 0) {
                    const {total, datas, receivePeople, receiveNum, useNum, overdueNum} = res.data;
                    this.receiveLog = datas;
                    this.receiveLog.receivePeople = receivePeople;
                    this.receiveLog.receiveNum = receiveNum;
                    this.receiveLog.useNum = useNum;
                    this.receiveLog.overdueNum = overdueNum;
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, 'pagination', 'init', {
                            total: total,
                            pageSize,
                            currentPage: pageNo
                        })
                    }
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .result-panel-header
        span
            &:not(:last-of-type)
                margin-right 30px
</style>
